var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-list", {
        key: _vm.reInit,
        attrs: {
          payload: _vm.payload,
          module: _vm.MODULE,
          model: _vm.modelObj,
          moduleTitle: "message.layout.chemicallist",
          propId: "chemicallist_id",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
            persistent: "",
            fullscreen: "",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-0 ma-0" },
            [
              _c(
                "v-app-bar",
                { attrs: { color: "primary", dense: "", dark: "", fixed: "" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "message.checklist." +
                            (_vm.checklistId
                              ? "updateChecklist"
                              : "addCheckList")
                        )
                      )
                    ),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", fab: "", small: "", dark: "" },
                      on: { click: _vm.closeDialogHandler },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "pa-0 ma-0",
                  staticStyle: { position: "absolute", top: "50px" },
                },
                [
                  _vm.dialog
                    ? _c("form-render", {
                        attrs: {
                          formId: _vm.formId,
                          module: _vm.MODULE,
                          propId: "chemicallist_id",
                          checklistId: _vm.checklistId,
                          formName: _vm.formName,
                          checklistName: _vm.checklistTitle,
                          selectedItemId: _vm.selectedItemId,
                          projectChecklistFormData:
                            _vm.projectChecklistFormData,
                          checklistEmail: _vm.checklistEmail,
                          canSendEmail: _vm.canSendEmail,
                          dataGridImportValue: _vm.dataGridImportValue,
                          checklistData: _vm.checklistData,
                          checklistTemplateData: _vm.checklistTemplateData,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }